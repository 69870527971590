import ng from 'angular';
import acpCore from 'core';
import acpComponentAnytimeAlertsDomain from 'components/anytime-alerts-domain';
import { AcpPushNotificationPlugin } from './acp-push-notification-plugin';
import { AcpPushNotificationPluginClient } from './acp-push-notification-plugin-client';

export const acpComponentPushNotificationDomain = ng
  .module('acp.component.push-notification-mobule', [
    acpCore.name,
    acpComponentAnytimeAlertsDomain.name
  ])
  .service('acpPushNotificationPlugin', AcpPushNotificationPlugin)
  .service('acpPushNotificationPluginClient', AcpPushNotificationPluginClient);

export { AcpPushNotificationPlugin };
export * from './types';
