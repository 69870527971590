import ng from 'angular';
import { AcpDeviceServiceProvider } from 'core';
import AcpPushNotificationPluginClient from './acp-push-notification-plugin-client';
import {
  AlertSubscription,
  AddAlertSubscriptionRequest,
  EditAlertSubscriptionRequest,
  PushPlugin,
  RegistrationResponse,
  SubscriptionList,
  NotificationEventResponse,
  ChannelList
} from './types';

export class AcpPushNotificationPlugin {
  DEVICE_TOKEN_STORAGE_KEY = 'device_token';
  isCordova: boolean = this.acpDeviceService.isCordova();
  isAndroid: boolean = this.acpDeviceService.isAndroid();
  savedToken: string = this.nsStorage.local(this.DEVICE_TOKEN_STORAGE_KEY);
  pushNotificationInstance: PushPlugin = null;
  deviceAlertSubscrition: AlertSubscription;
  loading = this.nsSignal.create<boolean>();
  onRegistrationHandler: (data: RegistrationResponse) => void;
  notificationChannel: ChannelList = {
    marketing: {
      id: 'Marketing',
      description: 'Marketing',
      importance: 3
    }
  };

  constructor(
    private $rootScope: ng.IRootScopeService,
    private nsStorage: any,
    private acpAnytimeAlertsService: any,
    private $window: ng.IWindowService,
    private acpDeviceService: AcpDeviceServiceProvider,
    private nsPermissions: nsUtils.NsPermissionsService,
    private nsSignal: nsUtils.NsSignalService,
    private acpPushNotificationPluginClient: AcpPushNotificationPluginClient,
    private nsConfig: any,
  ) {
    'ngInject';

    this.acpAnytimeAlertsService.deviceTokens.onValue(
      (subs: AlertSubscription[]) => {
        if (Array.isArray(subs)) {
          this.deviceAlertSubscrition = subs.find(
            (s) => s.address === this.savedToken
          );
        }
      }
    );

    this.onRegistrationHandler = this.onRegistration.bind(this);
  }

  listen(): void {
    this.initializeNotificationEvent();
  }

  createPluginInstance() {
    this.pushNotificationInstance = this.$window.PushNotification.init({
      ios: {
        alert: true,
        badge: true,
        sound: true,
        clearBadge: true
      },
      android: {
        sound: true,
        clearBadge: true
      },
      config: {
        themeColor: this.nsConfig.get('push_notification_icon_color')
      }
    });
    // error handler
    this.pushNotificationInstance.on('error', (error) => {
      this.$window.console.log('Push Notification ', error);
      if (error.message.includes('was denied by the user')) {
        this.setLoading(false);
      }
    });
  }

  getDeviceToken(): Promise<string> {
    return new Promise((resolve) => {
      this.createPluginInstance();
      this.pushNotificationInstance.on('registration', ({ registrationId }) => {
        if (registrationId !== '0000') {
          this.nsStorage.local(this.DEVICE_TOKEN_STORAGE_KEY, registrationId);
          this.savedToken = registrationId;
          resolve(registrationId);
        } else {
          this.nsStorage.removeLocalItem(this.DEVICE_TOKEN_STORAGE_KEY);
          resolve('');
        }
      });
      this.notificationListener();
    });
  }

  notificationListener() {
    this.pushNotificationInstance.on(
      'notification',
      (res: NotificationEventResponse) => {
        let messageId: string;
        try {
          messageId = this.isAndroid
            ? res.additionalData['pinpoint.jsonBody'].messageId
            : res.additionalData.data.jsonBody.messageId;
        } catch (error) {
          messageId = '';
        }

        if (res.additionalData.coldstart) {
          this.acpPushNotificationPluginClient
            .alertEvent({
              address: this.savedToken,
              event_type: 'MESSAGE_CLICKED',
              message_id: messageId,
              timestamp: new Date().toISOString()
            })
            .finally(() => {
              let url: string;
              try {
                url = this.isAndroid
                  ? res.additionalData.extra.ns_web_url ||
                    res.additionalData['pinpoint.url']
                  : res.additionalData.ns_web_url ||
                    res.additionalData.data.pinpoint.deeplink;
              } catch (error) {
                url = '';
              }
              if (url) {
                this.$window.location.assign(url);
              }
            });
        }
      }
    );
  }

  initializeNotificationEvent() {
    if (this.isCordova && this.savedToken && this.$window.PushNotification) {
      this.createPluginInstance();
      this.notificationListener();
    }
  }

  openDeviceSetting() {
    if (this.isCordova && this.$window.cordova.plugins.settings) {
      this.$window.cordova.plugins.settings.open('notification_id', ng.noop());
    }
  }

  setLoading(flag: boolean) {
    this.loading.emit(flag);
    this.$rootScope.$digest();
  }

  getDevicePermission(): Promise<boolean> {
    return new Promise((resolve) => {
      this.$window.PushNotification.hasPermission(
        ({ isEnabled }) => resolve(isEnabled),
        ng.noop
      );
    });
  }

  syncDeviceNotification(isLogin?: boolean): void {
    if (this.isCordova && this.$window.PushNotification) {
      this.getDevicePermission().then((enabled: boolean) => {
        isLogin
          ? this.unSubscribePushNotification(enabled)
          : this.subscribePushNotification(enabled);
      });
    }
  }

  async subscribedDevice(): Promise<AlertSubscription> {
    await this.getDeviceToken();
    return new Promise((resolve, _reject) => {
      if (!this.deviceAlertSubscrition) {
        this.acpAnytimeAlertsService.load().then(
          () => resolve(this.deviceAlertSubscrition),
          () => this.setLoading(false)
        );
      } else {
        resolve(this.deviceAlertSubscrition);
      }
    });
  }

  // Unsubscribe push notification if device permission is false
  unSubscribePushNotification(hasPermission: boolean) {
    if (this.savedToken) {
      this.subscribedDevice().then((deviceInfo: AlertSubscription) => {
        if (!deviceInfo) {
          return;
        } else if (!hasPermission && deviceInfo.alert_enabled) {
          this.updateAlert(false);
        } else if (hasPermission && !deviceInfo.alert_enabled) {
          this.updateAlert(true);
        }
      });
    }
  }

  // subscribe/update push notificaion
  subscribePushNotification(hasPermission: boolean) {
    this.subscribedDevice().then((deviceInfo: AlertSubscription) => {
      if (!deviceInfo) {
        this.initializePluginAndRegister(hasPermission);
      } else if (!hasPermission && deviceInfo.alert_enabled) {
        this.updateAlert(false);
      } else if (hasPermission && !deviceInfo.alert_enabled) {
        this.updateAlert(true);
      } else {
        this.setLoading(false);
      }
    });
  }

  initializePluginAndRegister(hasPermission: boolean) {
    // don't register if android device permission is false initially
    if (this.isAndroid && !hasPermission) {
      this.setLoading(false);
      return;
    }

    this.createPluginInstance();
    this.pushNotificationInstance.on(
      'registration',
      this.onRegistrationHandler
    );
    this.notificationListener();
  }

  onRegistration(data: RegistrationResponse) {
    if (data.registrationId !== '0000') {
      this.nsStorage.local(this.DEVICE_TOKEN_STORAGE_KEY, data.registrationId);
      this.savedToken = data.registrationId;
      this.addAlert();
    } else {
      this.nsStorage.removeLocalItem(this.DEVICE_TOKEN_STORAGE_KEY);
      this.setLoading(false);
    }
  }

  async setAllSubscription(): Promise<SubscriptionList> {
    const permissions = await this.nsPermissions.requestPermissions([
      'hasDirectDepositAvailable',
      'netspendPaybackLoyaltyEligible',
      'isPaybackRewardsEligible',
      'isMarketingPromotionSubscriptionEligible',
      'isPushNotificationHoldAlertTypeEnabled'
    ]);
    const alertTypes = [
      { name: 'per_transaction', permission: '' },
      { name: 'balance_weekly', permission: '' },
      { name: 'budgets', permission: '' },
      { name: 'budget_over', permission: '' },
      { name: 'budget_approaching', permission: '' },
      { name: 'deposit_only', permission: 'hasDirectDepositAvailable' },
      { name: 'on_decline', permission: '' },
      { name: 'holds', permission: 'isPushNotificationHoldAlertTypeEnabled' },
      { name: 'payback_rewards', permission: 'isPaybackRewardsEligible' },
      { name: 'payback_points', permission: 'netspendPaybackLoyaltyEligible' },
      {
        name: 'marketing_promotions',
        permission: 'isMarketingPromotionSubscriptionEligible'
      }
    ];
    return alertTypes.reduce((acc, nxt) => {
      acc[nxt.name] = nxt.permission ? !!permissions[nxt.permission] : true;
      return acc;
    }, {}) as SubscriptionList;
  }

  async addAlert() {
    this.setLoading(true);
    const subscriptions = await this.setAllSubscription();
    const payload: AddAlertSubscriptionRequest = {
      address: this.savedToken,
      address_type: 'device_token',
      device_os: this.acpDeviceService.isIos() ? 'IOS' : 'ANDROID',
      ...subscriptions
    };
    this.acpAnytimeAlertsService
      .addAlert(payload)
      .then(() => {
        if (subscriptions.marketing_promotions) {
          this.manageChannel('marketing');
        }
      })
      .finally(() => this.setLoading(false));
    // unsubcribing registration event
    this.pushNotificationInstance.off(
      'registration',
      this.onRegistrationHandler
    );
  }

  updateAlert(isOn: boolean) {
    const payload: EditAlertSubscriptionRequest = {
      address: this.savedToken,
      alert_enabled: isOn
    };
    this.acpAnytimeAlertsService
      .editAlert(payload)
      .finally(() => this.setLoading(false));
  }

  manageChannel(channel: keyof ChannelList, isCreate = true) {
    if (this.isCordova && this.isAndroid && this.$window.PushNotification) {
      if (isCreate) {
        this.$window.PushNotification.createChannel(
          () => console.log(`${channel} created`),
          () => console.log(`Error: creating '${channel}' channel`),
          this.notificationChannel[channel]
        );
      } else {
        this.$window.PushNotification.deleteChannel(
          () => console.log(`${channel} deleted`),
          () => console.log(`Error: deleteing '${channel}' channel`),
          this.notificationChannel[channel].id
        );
      }
    }
  }
}
